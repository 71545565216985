
import { Options } from 'vue-class-component'
import { Mixins, Prop, PropSync } from 'vue-property-decorator'
import InputOnlyNumberMixin from '@/components/mixins/InputOnlyNumberMixin.vue'

@Options({
  emits: ['update:trackpoint'],
  name: 'InputOnlyNumber',
})
export default class InputOnlyNumber extends Mixins(InputOnlyNumberMixin) {
  @PropSync('modelValue', { type: Number }) _modelValue?: number
  @Prop({ type: Boolean }) readonly controls = false
  @Prop({ type: Number }) readonly min?: number
  @Prop({ type: Number }) readonly max?: number
  @Prop({ type: Number }) readonly precision?: number
  @Prop({ type: Boolean }) readonly disabled?: boolean

  updateTrackPoint() {
    this.$emit('update:trackpoint')
  }
}
